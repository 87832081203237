import { ChartOptions, Interaction } from "chart.js"
import "chart.js/auto"
import { Chart } from "react-chartjs-2"
import { utils } from "../../../dfi-utils"
import { useDataContext } from "../components/chart-data-context"
import { useChart } from "../components/context"
import { datalabels } from "../plugins/datalabels"
import { highlightLegendData, hoverPlugin, resetChart } from "../plugins/hover"
import { LABEL_FONT_SIZE, LABEL_WIDTH, wrapText } from "../utils"

const LineChart: React.FC<any> = ({ data }) => {
    const { form } = useChart()
    const dctx = useDataContext()
    const { chart } = form.values
    const { type, yAxis, labelwidth = LABEL_WIDTH } = chart
    const unit = utils.buildUnit(yAxis.unit, yAxis.prefix, yAxis.space)

    // Chart options
    const options: ChartOptions<"line"> = {
        ...form.values.chart.chartjs.options,
        animation: false,
        indexAxis: "x",
        interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
        },
        scales: {
            y: {
                stacked: yAxis.grouped === "stack",
                type: yAxis.logscale ? "logarithmic" : "linear",
                min: yAxis.min ?? undefined,
                max: yAxis.max ?? undefined,
                suggestedMin: dctx.getAxisLimit("yAxis", "min"),
                suggestedMax: dctx.getAxisLimit("yAxis", "max"),
                ticks: {
                    display: !chart.yAxis.hideAxislabels,
                    callback: (v) =>
                        `${utils.displayCompact(v as number, unit, yAxis.precision, yAxis.prefix)}`,
                },
                grid: {
                    display: !chart.yAxis.hideGridlines,
                },
                border: {
                    display: false,
                },
            },
            x: {
                type: "linear",
                min: dctx.time.min,
                max: dctx.time.max,
                ticks: {
                    display: chart.categorylabels,
                    format: {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                        useGrouping: false,
                    },
                    //includeBounds: false,
                },
                grid: {
                    display: chart.categorylabels,
                    drawOnChartArea: false,
                    drawTicks: true,
                },
                border: {
                    display: chart.categorylabels,
                },
            },
        },
        layout: {
            padding: {
                right: chart.datalabels ? labelwidth * (LABEL_FONT_SIZE * 0.6) : 0,
            },
        },
        onHover: (e, active, chart) => {
            const nearestElement = Interaction.modes.dataset(chart, e, { intersect: false })
            hoverPlugin(e, nearestElement, chart, type)
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: !!chart.tiles.codes.length,
                text: wrapText(data.title, 50),
                align: "start",
            },
            datalabels: {
                display: chart.datalabels,
                formatter: (label) => wrapText(label, labelwidth),
                listeners: {
                    enter: (ctx) => {
                        highlightLegendData(ctx.datasetIndex, type)
                    },
                    leave: () => resetChart(type),
                },
            },
            tooltip: {
                itemSort: (a, b) => b.parsed.y - a.parsed.y,
                callbacks: {
                    title: (ctx) => ctx[0].parsed.x.toString(),
                    label: (ctx: any) => {
                        const label = ctx.dataset.label
                        return `${label}: ${utils.displayCompact(
                            ctx.parsed.y,
                            unit,
                            yAxis.precision,
                            yAxis.prefix,
                        )}`

                        // const scale = ctx.chart.scales.y
                        // const mouseY = ctx.chart.tooltip._eventPosition.y
                        // const pointY = scale.getPixelForValue(ctx.parsed.y)
                        // const isHovered = Math.abs(mouseY - pointY) < 5

                        // return isHovered ? `<b>${labelText}</b>` : labelText
                    },
                },
            },
        },
    }

    return <Chart type="line" options={options} data={data} plugins={[datalabels]} />
}

export default LineChart
