import {
    ActionIcon,
    Box,
    Button,
    Center,
    Code,
    CopyButton,
    Flex,
    Group,
    List,
    Menu,
    Overlay,
    Popover,
    SimpleGrid,
    Text,
    Title,
    useMantineTheme,
} from "@mantine/core"
import { useElementSize } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import {
    IconChartLine,
    IconCode,
    IconCopy,
    IconDownload,
    IconEdit,
    IconInfoCircle,
    IconLink,
    IconPhoto,
    IconPhotoDown,
    IconShare,
    IconTable,
} from "@tabler/icons-react"
import { ChartData, defaults as ChartJSDefaults } from "chart.js"
import html2canvas from "html2canvas"
import _ from "lodash"
import React, { useState } from "react"
import { DataTable, dt, utils } from "../../../dfi-utils"
import DFICircleLogo from "../../../dfi-utils/src/assets/dfi-circle-logo-green-black.svg"
import BarChart from "../charts/barchart"
import Dotplot from "../charts/dotplot"
import LineChart from "../charts/linechart"
import Map from "../charts/map"
import Marimekko from "../charts/marimekko"
import ScatterPlot from "../charts/scatterplot"
import Timeline from "../charts/timeline"
import { ADMIN_TOOL_PATH, ChartType, getTilesPerRow } from "../config"
import { generateDatasets } from "../generate-datasets"
import { highlightLegendData, resetChart } from "../plugins/hover"
import {
    CANVAS_HEIGHT,
    CANVAS_WIDTH,
    CHART_HEIGHT,
    chartEmbedCode,
    dataTableFormatter,
    MIN_CHART_HEIGHT,
    shareChartUrl,
    tileIndicatorsCode,
} from "../utils"
import { useDataContext } from "./chart-data-context"
import { useChart } from "./context"
import DFILegend from "./legend"
import ChartOverlayOptions, { ChartOverlayDisplay, OverlayType } from "./overlay"

ChartJSDefaults.font.family = "Roboto"
ChartJSDefaults.font.size = 11
ChartJSDefaults.color = "#555"

const ComponentMap = {
    [ChartType.BarChart]: BarChart,
    [ChartType.LineChart]: LineChart,
    [ChartType.ScatterPlot]: ScatterPlot,
    [ChartType.Marimekko]: Marimekko,
    [ChartType.Dotplot]: Dotplot,
    [ChartType.Map]: Map,
}

interface MenuItemProps {
    label: string
    icon: React.ReactNode
    onClick: () => void
}

interface DFIChartProps {
    adminControls?: boolean
    containerRef?: React.RefObject<HTMLDivElement>
    clone?: boolean
    setRenderClone?: (value: boolean) => void
}

type ActiveTabType = "chart" | "table"

const DFIChart: React.FC<DFIChartProps> = ({
    adminControls,
    containerRef,
    clone,
    setRenderClone,
}) => {
    const theme = useMantineTheme()
    const chartContext = useChart()
    const dctx = useDataContext()
    const { table, form } = chartContext

    const { time, type, height } = form.values.chart
    const [overlay, setOverlay] = useState<OverlayType | null>(null)
    const [activeTab, setActiveTab] = useState<ActiveTabType | null>("chart")

    const data = generateDatasets(chartContext, dctx)

    const style: React.CSSProperties = {
        border: `1px solid ${theme.colors.gray[3]}`,
        borderRadius: 5,
    }

    return (
        <Flex
            pos="relative"
            direction="column"
            style={style}
            p="md"
            w="100%"
            gap={5}
            className="dfi-chart-container"
            h={height || CHART_HEIGHT}
        >
            {!clone && overlay && (
                <ChartOverlayDisplay overlay={overlay} onClose={() => setOverlay(null)} />
            )}
            <DFIChartHeader />

            {!clone && activeTab === "chart" && <ChartOverlayOptions onClick={setOverlay} />}

            {activeTab === "chart" ? (
                <>
                    <DFILegendContainer />

                    <DFIChartGridContainer data={data} />

                    {!clone && !!time.codes.length && time.display && (
                        <Timeline range={type === ChartType.LineChart} />
                    )}
                </>
            ) : (
                <Box style={{ overflow: "auto", flex: 1 }}>
                    <DataTable
                        table={table}
                        options={{
                            hideTitle: true,
                            hideOptions: true,
                            formatter: dataTableFormatter(chartContext),
                        }}
                    />
                </Box>
            )}
            <DFIChartFooter
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                adminControls={adminControls}
                containerRef={containerRef}
                setRenderClone={setRenderClone}
            />
        </Flex>
    )
}

interface DFILegendContainerProps {}

const DFILegendContainer: React.FC<DFILegendContainerProps> = () => {
    const { form } = useChart()
    const dctx = useDataContext()
    const { chart } = form.values
    const colname = chart.type === ChartType.ScatterPlot ? "colors" : "yAxis"

    const isSingleIndicator =
        chart.yAxis.codes.length === 1 || chart.tiles.codes.includes(tileIndicatorsCode)
    const hideLegend =
        (colname === "yAxis" && isSingleIndicator) ||
        chart.type === ChartType.LineChart ||
        chart.type === ChartType.Map

    const legendData = _.map(chart[colname]?.selected, (value) => ({
        label: dctx.getDisplayLabel(colname, value),
        color: dctx.legendData[value],
    }))

    return (
        chart.legend &&
        !hideLegend && (
            <Box m={5}>
                <DFILegend
                    data={legendData}
                    onHover={(index) => highlightLegendData(index, chart.type)}
                    onMouseOut={() => resetChart(chart.type)}
                />
            </Box>
        )
    )
}

interface DFIChartGridContainerProps {
    data: ChartData[]
}
const DFIChartGridContainer: React.FC<DFIChartGridContainerProps> = ({ data }) => {
    const { form } = useChart()
    let { type, numcols } = form.values.chart
    const { ref, width, height } = useElementSize()

    numcols = numcols ?? getTilesPerRow(type)

    const ChartComponent = ComponentMap[type]
    const numChartCols = width >= 400 && data.length > 1 ? numcols : 1
    const numChartRows = Math.ceil(data.length / numChartCols)
    const chartHeight = height / numChartRows

    const maxVisibleRows = Math.floor(height / MIN_CHART_HEIGHT)
    const minChartHeight = height / maxVisibleRows

    return (
        <Box ref={ref} key={data.length} style={{ overflow: "auto", flex: 1 }}>
            <SimpleGrid
                key={width + height + data.length}
                cols={numChartCols}
                spacing="xs"
                verticalSpacing={0}
                h="100%"
                style={{ gridAutoRows: "1fr" }}
                className="dfi-chart-grid"
            >
                {data.map((dataset: ChartData, index: number) => {
                    return (
                        <Box
                            key={index}
                            mih={minChartHeight}
                            h={chartHeight}
                            pos="relative"
                            onPointerLeave={() => resetChart(type)}
                        >
                            <ChartComponent data={dataset} index={index} />
                        </Box>
                    )
                })}
            </SimpleGrid>
        </Box>
    )
}
const DFIChartHeader: React.FC = () => {
    const { form } = useChart()
    const { text } = form.values.chart
    return (
        <Group position="apart" align="start" noWrap>
            <Box>
                <Text fz={14} fw={500}>
                    {text.title}
                </Text>
                <Text fz={12} c="gray">
                    {text.subtitle}
                </Text>
            </Box>
            <Box m={-5}>
                <img src={DFICircleLogo} width={40} height={40} />
            </Box>
        </Group>
    )
}

const ChartInfo: React.FC = () => {
    const { form } = useChart()
    const dctx = useDataContext()
    const { dimensions, tiles, time, yAxis } = form.values.chart

    const tilesLabel = dctx.tiles.nunique > 1 && !tiles.locked ? tiles.label ?? "Tiles" : null
    const dimensionLabel =
        dctx.dimensions.nunique > 1 && !dimensions.locked ? dimensions.label ?? "Dimensions" : null
    const indicatorLabel =
        yAxis.codes.length > 1 && !yAxis.locked ? yAxis.label ?? "Indicators" : null

    const overlayLabel = tilesLabel || dimensionLabel || indicatorLabel

    return (
        <Box>
            <Title size="h6" mb={10}>
                How to interact with this chart
            </Title>
            <List spacing="xs">
                {overlayLabel && (
                    <List.Item>
                        Click on{" "}
                        <Text style={{ display: "inline" }} c="blue">
                            Select {overlayLabel}
                        </Text>{" "}
                        above the chart to choose other metrics from this dataset
                    </List.Item>
                )}
                <List.Item>
                    Use the share icon to download this chart, embed it or download the data
                </List.Item>
                <List.Item>
                    Use the icon next to the share button to toggle between chart and table views
                </List.Item>
                {!!time.codes.length && time.display && (
                    <List.Item>
                        Use the Play button below the chart to view changes over time
                    </List.Item>
                )}
            </List>
        </Box>
    )
}

interface FeatureImageSettingProps {
    datalabels: boolean
    xGrid?: boolean
    xLabels?: boolean
    yGrid?: boolean
    yLabels?: boolean
}

interface DFIChartFooterProps {
    activeTab: ActiveTabType | null
    setActiveTab: (tab: ActiveTabType) => void
    containerRef?: React.RefObject<HTMLDivElement>
    adminControls?: boolean
    setRenderClone?: (value: boolean) => void
}

const DFIChartFooter: React.FC<DFIChartFooterProps> = ({
    activeTab,
    setActiveTab,
    containerRef,
    adminControls,
    setRenderClone,
}) => {
    const { form, table } = useChart()
    const dctx = useDataContext()
    const { text } = form.values.chart
    const [open, setOpen] = useState<boolean>(false)
    const [modal, setModal] = useState<string | null>(null)
    const downloadFileName = `${form.values.chart.text.title ?? "Data For India"}`

    function download(filtered?: boolean) {
        const data = dt.toCsv(table, filtered ? { rows: dctx.filteredIndices } : {})
        const filename = `${downloadFileName}.csv`
        utils.downloadFile(data, filename, "csv")
    }

    function downloadImage() {
        if (!containerRef || !setRenderClone) {
            return
        }
        setRenderClone(true)
        setTimeout(() => {
            html2canvas(containerRef.current!).then((canvas) => {
                const data = canvas.toDataURL("image/jpeg")
                const filename = `${downloadFileName}.jpg`
                utils.downloadFile(data, filename, "image")
                setRenderClone(false)
            })
        }, 500)
    }

    function createFeatureImage(prevSettings: FeatureImageSettingProps) {
        html2canvas(document.querySelector(".dfi-chart-grid") as HTMLElement, {
            onclone: (doc) => {
                const chartGrid = doc.querySelector(".dfi-chart-grid") as HTMLElement
                chartGrid.style.border = "10px solid white"
            },
        }).then((canvas) => {
            const resizedCanvas = document.createElement("canvas")
            const resizedContext = resizedCanvas.getContext("2d")!

            resizedCanvas.width = CANVAS_WIDTH
            resizedCanvas.height = CANVAS_HEIGHT

            resizedContext.drawImage(canvas, 0, 0, CANVAS_WIDTH, CANVAS_HEIGHT)

            const data = resizedCanvas.toDataURL("image/jpeg")
            const filename = `${downloadFileName}-feature.jpg`
            utils.downloadFile(data, filename, "image")

            // Reset chart settings
            form.setFieldValue("chart.datalabels", prevSettings.datalabels)
            form.setFieldValue("chart.yAxis.hideGridlines", prevSettings.yGrid)
            form.setFieldValue("chart.yAxis.hideAxislabels", prevSettings.yLabels)
            form.setFieldValue("chart.categorylabels", true)

            if (form.values.chart.xAxis) {
                form.setFieldValue("chart.xAxis.hideGridlines", prevSettings.xGrid)
                form.setFieldValue("chart.xAxis.hideAxislabels", prevSettings.xLabels)
            }
            notifications.show({
                message: "Feature Image Created",
                autoClose: 5000,
            })
        })
    }

    function handleCreateFeatureImage() {
        const prevSettings: FeatureImageSettingProps = {
            datalabels: form.values.chart.datalabels,
            xGrid: form.values.chart.xAxis?.hideGridlines,
            xLabels: form.values.chart.xAxis?.hideAxislabels,
            yGrid: form.values.chart.yAxis.hideGridlines,
            yLabels: form.values.chart.yAxis.hideAxislabels,
        }

        // Set chart settings for feature image
        form.setFieldValue("chart.datalabels", false)
        form.setFieldValue("chart.yAxis.hideGridlines", true)
        form.setFieldValue("chart.yAxis.hideAxislabels", true)
        form.setFieldValue("chart.categorylabels", false)

        if (form.values.chart.xAxis) {
            form.setFieldValue("chart.xAxis.hideGridlines", true)
            form.setFieldValue("chart.xAxis.hideAxislabels", true)
        }
        setTimeout(() => {
            createFeatureImage(prevSettings)
        }, 500)
    }

    const createMenuItem = (
        label: string,
        icon: React.ReactNode,
        onClick: () => void,
    ): MenuItemProps => ({
        label,
        icon,
        onClick,
    })

    const imageOptions: MenuItemProps[] =
        activeTab === "chart"
            ? [
                  createMenuItem("Download Image", <IconPhotoDown size="1em" />, downloadImage),
                  ...(form.values.code && adminControls
                      ? [
                            createMenuItem(
                                "Create Feature Image",
                                <IconPhoto size="1em" />,
                                handleCreateFeatureImage,
                            ),
                        ]
                      : []),
              ]
            : []

    const dataOptions: MenuItemProps[] = [
        createMenuItem("Download Chart Data", <IconDownload size="1em" />, () => download(true)),
        createMenuItem("Download Full Data", <IconDownload size="1em" />, download),
    ]

    const codeOptions: MenuItemProps[] = form.values.code
        ? [
              createMenuItem("Embed", <IconCode size="1em" />, () => setModal("embed")),
              createMenuItem("Copy Link", <IconLink size="1em" />, () => {
                  const url = shareChartUrl(form.values.code!)
                  navigator.clipboard.writeText(url)
              }),
              ...(adminControls
                  ? [
                        createMenuItem("Edit on Admin Tools", <IconEdit size="1em" />, () => {
                            window.open(
                                `${ADMIN_TOOL_PATH}/data?draftid=${form.values.code}`,
                                "_blank",
                            )
                        }),
                    ]
                  : []),
          ]
        : []

    const options: MenuItemProps[] = [...imageOptions, ...dataOptions, ...codeOptions]

    const embedCode = chartEmbedCode(form.values.code!, form.values.chart.height ?? CHART_HEIGHT)

    return (
        <>
            <Box fz={10} c="gray" pos="relative">
                <Text>{text.notes}</Text>
                {text.sources?.length ? <Text>{`Source: ${text.sources}`}</Text> : null}
                <Text>{text.footer}</Text>
                <Group
                    pos="absolute"
                    bottom={0}
                    right={0}
                    position="right"
                    noWrap
                    align="baseline"
                    spacing="xs"
                    data-html2canvas-ignore="true"
                >
                    <Popover width={250} position="bottom" withArrow shadow="md">
                        <Popover.Target>
                            <ActionIcon variant="light">
                                <IconInfoCircle size="1.2em" />
                            </ActionIcon>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <ChartInfo />
                        </Popover.Dropdown>
                    </Popover>
                    <ActionIcon
                        variant="light"
                        onClick={() => setActiveTab(activeTab === "chart" ? "table" : "chart")}
                    >
                        {activeTab === "chart" ? (
                            <IconTable size="1.2em" />
                        ) : (
                            <IconChartLine size="1.2em" />
                        )}
                    </ActionIcon>
                    <Menu
                        opened={open}
                        onChange={setOpen}
                        position="top-end"
                        data-html2canvas-ignore="true"
                    >
                        <Menu.Target>
                            <ActionIcon variant="light">
                                <IconShare size="1.2em" />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown data-html2canvas-ignore="true">
                            {options.map((option, index) => (
                                <Menu.Item key={index} icon={option.icon} onClick={option.onClick}>
                                    {option.label}
                                </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Box>

            {modal === "embed" && (
                <Overlay>
                    <Center m="md">
                        <Group position="apart" align="start" bg="white" p="md" noWrap>
                            <Code fz="sm" p="sm">
                                {embedCode}
                            </Code>
                            <CopyButton value={embedCode}>
                                {({ copied, copy }) => (
                                    <Button
                                        compact
                                        variant="light"
                                        size="sm"
                                        onClick={() => {
                                            copy()
                                            setTimeout(() => setModal(null), 400)
                                        }}
                                        leftIcon={<IconCopy size="1em" />}
                                        color={copied ? "teal" : "gray"}
                                    >
                                        Copy & Close
                                    </Button>
                                )}
                            </CopyButton>
                        </Group>
                    </Center>
                </Overlay>
            )}
        </>
    )
}

export default DFIChart
